<template>
  <div class="bindUser">
    <div class="bindUser_search">
      <!-- <el-cascader
        class="el_cascader"
        v-model="deptId"
        :options="deptTree"
        :props="{ checkStrictly: true, value: 'id' }"
        :show-all-levels="false"
        @change="handleChange"
      ></el-cascader> -->
      <!-- <el-button v-if="btnType" @click="reset">重置</el-button> -->
      <el-input
        @input="handleInput"
        v-model="username"
        placeholder="请输入用户账号"
      ></el-input>
      <el-button type="primary" @click="openBind">添加用户</el-button>
      <el-button type="danger" @click="getRoleDeleteUR">取消关联</el-button>
    </div>
    <div class="bindUser_addbtn"></div>
    <el-table
      border
      stripe
      v-loading="loading"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="username" label="用户账号"> </el-table-column>
      <el-table-column prop="deptName" label="部门"> </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageObj.current"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageObj.total"
        background
      >
      </el-pagination>
    </div>
    <el-dialog
      class="innerVisible_box"
      width="35%"
      title="选择用户"
      :visible.sync="innerVisible"
      append-to-body
    >
      <div class="tree_content">
        <!-- <el-cascader
          class="el_cascader"
          v-model="deptId1"
          :options="deptTree"
          :props="{ checkStrictly: true, value: 'id' }"
          :show-all-levels="false"
        ></el-cascader> -->
        <el-input
          @input="handleInput1"
          v-model="username1"
          placeholder="请输入用户账号"
        ></el-input>
        <el-button type="primary" @click="getRoleUpdateUP">关联</el-button>
      </div>
      <el-table
        border
        stripe
        v-loading="loading1"
        :data="tableData1"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange1"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="username" label="用户账号"> </el-table-column>
        <el-table-column prop="deptName" label="部门"> </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="pageObj1.current"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageObj1.total"
          background
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    paramsObj: Object,
  },
  name: "BindUser",
  data() {
    return {
      username: null,
      username1: "",
      deptTree: [],
      btnType: false,
      tableData: [],
      tableData1: [],
      innerVisible: false,
      page: 1,
      page1: 1,
      size: 10,
      size1: 10,
      pageObj: {
        current: 1,
        total: 0,
      },
      pageObj1: {
        current: 1,
        total: 0,
      },
      loading: false,
      loading1: false,
      reUserIds1: [],
      reUserIds: [],
    };
  },
  watch: {
    name: {
      handler(val) {
        if (val || this.deptId) {
          this.btnType = true;
        } else {
          this.btnType = false;
        }
      },
      immediate: true,
    },
    deptId: {
      handler(val) {
        if (val || this.deptId) {
          this.btnType = true;
        } else {
          this.btnType = false;
        }
      },
      immediate: true,
    },
  },
  created() {
    // this.getDeptTree();
    this.getFindBindUser();
  },
  methods: {
    // async getDeptTree() {
    //   const { data } = await this.$api.getDeptTree({
    //     deptId: JSON.parse(window.sessionStorage.getItem("userInfo")).deptId,
    //   });
    //   this.deptTree = data;
    // },
    handleInput() {
      this.page = 1;
      this.size = 10;
      this.getFindBindUser();
    },
    handleInput1() {
      this.page1 = 1;
      this.size1 = 10;
      this.getFindNoBindUser();
    },
    //查询角色关联用户
    async getFindBindUser() {
      this.loading = true;
      const { data } = await this.$api.getFindBindUser({
        page: this.page,
        size: this.size,
        id: this.paramsObj.id,
        username: this.username,
      });
      this.tableData = data.records;
      this.pageObj = data;
      this.loading = false;
    },
    //查询角色未关联用户
    async getFindNoBindUser() {
      this.loading1 = true;
      const { data } = await this.$api.getFindNoBindUser({
        page: this.page1,
        size: this.size1,
        id: this.paramsObj.id,
        username: this.username1,
      });
      this.tableData1 = data.records;
      this.pageObj1 = data;
      this.loading1 = false;
    },
    // 关联用户
    async getRoleUpdateUP() {
      let reUserIds = [];
      this.reUserIds.forEach((item) => {
        reUserIds.push(item.id);
      });
      const res = await this.$api.getRoleUpdateUP({
        id: this.paramsObj.id,
        reUserIds: JSON.stringify(reUserIds),
      });
      this.$message({
        message: res.message,
        type: res.status === 200 ? "success" : "warning",
        duration: 1500,
      });
      this.getFindBindUser();
      this.innerVisible = false;
    },
    // 取消关联用户
    async getRoleDeleteUR() {
      let reUserIds = [];
      this.reUserIds1.forEach((item) => {
        reUserIds.push(item.id);
      });
      const res = await this.$api.getRoleDeleteUR({
        id: this.paramsObj.id,
        reUserIds: JSON.stringify(reUserIds),
      });
      this.$message({
        message: res.message,
        type: res.status === 200 ? "success" : "warning",
        duration: 1500,
      });
      this.getFindBindUser();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getFindBindUser();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getFindBindUser();
    },
    handleSizeChange1(val) {
      this.size1 = val;
      this.getFindNoBindUser();
    },
    handleCurrentChange1(val) {
      this.page1 = val;
      this.getFindNoBindUser();
    },
    handleSelectionChange(val) {
      this.reUserIds1 = val;
    },
    handleSelectionChange1(val) {
      this.reUserIds = val;
    },
    // handleChange(value) {
    //   console.log(value);
    // },
    openBind() {
      this.innerVisible = true;
      this.getFindNoBindUser();
    },
    reset() {
      this.name = "";
      this.deptId = "";
    },
  },
};
</script>

<style lang="scss" scoped>
// .el-cascader {
//   width: 20% !important;
//   margin-right: 20px;
// }
.el-input {
  width: 40% !important;
}
.el-button {
  margin: 0 20px;
}
.tree_content {
  margin-bottom: 20px;
}
.page {
  margin-top: 20px;
  text-align: center;
}
.bindUser {
  &_addbtn {
    margin: 20px 0;
  }
}
</style>
